<template>
  <div
    class="mb-0"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-8' : 'px-4'"
  >
    <!-- ==== DATA PENJUALAN PER TAHUNAN ==== -->
    <div class="my-5">
      <div class="title text--secondary mb-3">
        Data Rekap Penjualan Tahunan
      </div>
      <div class="mb-3 d-flex align-center flex-wrap">

        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'xls'"
          :btnText="'Export Excel'"
          :btnIcon="'mdi-microsoft-excel'"
          :btnColor="'teal'"
          :url="`franchise/report/recap/transaction/tahunan/export_excel?recap_job_id=${$route.params.id}`"
          :title="'Laporan Penjualan Tahunan'"
          ref="base-export-excel"
          v-on:onClickExport="responseExport"
        />
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'pdf'"
          :btnText="'Export PDF'"
          :btnIcon="'mdi-file-pdf-box'"
          :btnColor="'#f40f02'"
          :url="`franchise/report/recap/transaction/tahunan/export_pdf?recap_job_id=${$route.params.id}`"
          :title="'Laporan Penjualan Tahunan'"
          ref="base-export-pdf"
          v-on:onClickExport="responseExport"
        />

        <v-btn
          depressed
          :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
          elevation="0"
          :disabled="process.run"
          color="#aaa9ad"
          class="white--text text-capitalize"
          @click="$refs['base-table'].isFilterVisible = true"
        >
          <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
          <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
        </v-btn>
      </div>

      <!-- ==== FILTER STATUS ==== -->
      <div v-if="status.year || status.store || status.cashier">
        Pencarian :

        <v-chip
          v-if="status.year"
          class="ma-2"
          close
          @click:close="handleCloseChips('year')"
        >
          Tahun: {{ reportYear }}
        </v-chip>
        <v-chip
          v-if="status.store"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('store')"
        >
          Outlet: {{ filternamestore }}
        </v-chip>
      </div>

      <!-- ==== DATA SUMMARY ==== -->
      <v-expansion-panels flat accordion v-model="panel" focusable>
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header
            class="title text--secondary"
            style="font-size:1rem !important;"
            >Rincian Penjualan Tahunan</v-expansion-panel-header
          >
          <v-expansion-panel-content
            class="pt-5 mb-2"
            style=" box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:5px;"
          >
            <v-row>
              <v-col cols="12" sm="3" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''"
                v-for="(item, key) in total" :key="key">
                <v-card class="box-shadow border-radius pa-2">
                  <v-card-actions class="pt-2 px-0">
                    <div style="width:100%">
                      <v-card-title
                        class="font-weight-bold text--secondary px-0 py-0 pb-1"
                        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: 11px; line-height: 1; height: 30px' : ''"
                        :class="$vuetify.breakpoint.name === 'xs' ? '' : 'body-2'"
                      >
                        {{ item.label }}
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="#d31145"
                              v-if="item.note.title != ''"
                              v-on="on"
                              v-bind="attrs"
                              size="20"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <div class="d-flex flex-column">
                            <span class="font-weight-bold"><b>{{item.note.title}}</b></span>
                            <span>{{ item.note.content }}</span>
                          </div>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text :class="$vuetify.breakpoint.name === 'xs' ? 'title text-second px-0' : 'headline text-second px-0'">
                        {{ item.value }}
                      </v-card-text>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ==== LIST PENJUALAN TAHUNAN ==== -->
      <div v-show="process.run && list.length < 1">
        <v-skeleton-loader
          :loading="process.run"
          transition="scale-transition"
          :tile="false"
          type="table-tbody"
        >
        </v-skeleton-loader>
      </div>

      <!-- ==== COMPONENT BASE TABLE ==== -->
      <base-table
        v-on:onResetFilter="resetFilter"
        v-on:onRetrieveDataStart="responseDataStart"
        v-on:onRetrieveDataEnd="responseDataEnd"
        v-show="displayBaseTable"
        :isExternalFilterButton="true"
        :isFilterOnHeader="true"
        ref="base-table"
        dateType="date"
        dateFormat="DD MMMM YYYY"
        :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'calc(100vh - 450px)'"
        :filterHeader="filterHeader_salesYearly"
        :single-select="singleSelect"
        :headers="headers"
        :retrieveDataURL="`franchise/report/recap/transaction/tahunan?recap_job_id=${$route.params.id}`"
        filterType="laporan"
      >

        <template v-slot:[`item.label_raw`]="{ item }">
          {{ item.label_raw | monthYear }}
        </template>

        <template v-slot:[`item.count`]="{ item }">
          {{ item.count | num_format }}
        </template>

        <template v-slot:[`item.product_sold_qty_sum`]="{ item }">
          {{ item.product_sold_qty_sum | num_format }}
        </template>

        <template v-slot:[`item.product_price_sum`]="{ item }">
          {{ item.product_price_sum }}
        </template>

        <template v-slot:[`item.product_purchase_price_sum`]="{ item }">
          {{ item.product_purchase_price_sum }}
        </template>

        <template v-slot:[`item.bruto_price_sum`]="{ item }">
          {{ item.bruto_price_sum }}
        </template>

        <template v-slot:[`item.disount_sum`]="{ item }">
          {{ item.disount_sum }}
        </template>

        <template v-slot:[`item.charge_sum`]="{ item }">
          {{ item.charge_sum }}
        </template>

        <template v-slot:[`item.tax_sum`]="{ item }">
          {{ item.tax_sum }}
        </template>

        <template v-slot:[`item.other_cost_sum`]="{ item }">
          {{ item.other_cost_sum }}
        </template>

        <template v-slot:[`item.grand_total_sum`]="{ item }">
          {{ item.grand_total_sum }}
        </template>

        <template v-slot:[`item.total_nett`]="{ item }">
          {{ item.total_nett }}
        </template>

        <template v-slot:filterTitle>
          Filter Ringkasan Penjualan Tahunan
        </template>

        <template v-slot:customFilter>
          <v-select
            class="mt-5"
            :items="year"
            outlined
            clearable
            dense
            color="#d31145"
            label="Pilih Tahun"
            v-model="reportYear"
          >
          </v-select>
          <v-autocomplete
            item-text="store_name"
            item-value="store_id"
            :items="outlet"
            outlined
            clearable
            dense
            color="#d31145"
            class="mt-4"
            label="Pilih Outlet"
            v-model="filterstore"
          >
          </v-autocomplete>
        </template>
      </base-table>

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-show="emptyData" />
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy, exportExcel } from "@/service/Axios";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import moment from "moment";
import Export from "@/components/Export";
export default {
  data() {
    return {
      panel: "",
      modalFilter: false,
      /**
       * DATA COMPONENT BASETABLE
       */
      displayBaseTable: false,
      emptyData: false,
      singleSelect: true,
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },

      headers: [
        { text: "Bulan", align: "center", width: 200, value: "label_raw" },
        {
          text: "Nama Outlet",
          align: "left",
          width: 200,
          value: "store_name",
        },
        {
          text: "Jml Transaksi",
          align: "center",
          width: 150,
          value: "count",
        },
        {
          text: "Total Produk Terjual",
          align: "center",
          width: 180,
          value: "product_sold_sum",
        },
        { 
          text: "Total Qty Produk Terjual", 
          align: "center", 
          width: 135, 
          value: "product_sold_qty_sum" },
        {
          text: "Total Harga Jual (Rp)",
          align: "end",
          width: 180,
          value: "product_price_sum",
        },
        {
          text: "Total Harga Beli (Rp)",
          align: "end",
          width: 180,
          value: "product_purchase_price_sum",
        },
        {
          text: "Total Bruto (Rp)",
          align: "end",
          width: 180,
          value: "bruto_price_sum",
        },
        {
          text: "Total Diskon (Rp)",
          align: "end",
          width: 180,
          value: "disount_sum",
        },
        {
          text: "Total Biaya Servis (Rp)",
          align: "end",
          width: 190,
          value: "charge_sum",
        },
        {
          text: "Total PPN (Rp)",
          align: "end",
          width: 180,
          value: "tax_sum",
        },
        {
          text: "Total Biaya Tambahan (Rp)",
          align: "end",
          width: 200,
          value: "other_cost_sum",
        },
        {
          text: "Total Pembulatan (Rp)",
          align: "end",
          width: 200,
          value: "round_sum",
        },
        {
          text: "Total Nominal Bayar (Rp)",
          align: "end",
          width: 200,
          value: "grand_total_sum",
        },
        {
          text: "Total Netto (Rp)",
          align: "end",
          width: 180,
          value: "nett_price_sum",
        },
      ],

      /**
       * END DATA COMPONENT BASETABLE
       */

      filterstore: "",
      filternamestore: "",
      filtercashier: "",
      filternamecashier: "",
      reportYear: moment(new Date().toISOString().substr(0, 10)).format(
        "YYYY"
      ),
      filterdate: {},
      options: {},
      year: [],
      loading: false,
      totalData: 8,
      hari: new Date().toISOString().substr(0, 10),

      process: {
        run: false,
        loading: false,
      },
      dialog: {
        filtered: false,
      },
      filterSave: {
        year: "",
        store: "",
      },
      status: {
        year: false,
        store: false,
      },
      outlet: [],
      cashier: [],
      list: [],
      total: {},
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      totalData: 0,
      selected: {
        pagination: 0,
      },
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  computed: {
    filterHeader_salesYearly() {
      return [
        {
          type: "custom",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
      ];
    },

    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },

  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
  },
  created() {
    this.fetchOutlet();
    this.listTahun();
  },
  methods: {
    /**
     * EVENTS
     */
    resetFilter() {
      this.$refs["base-table"].params.year &&
        delete this.$refs["base-table"].params.year;
      this.$refs["base-table"].params.store_name &&
        delete this.$refs["base-table"].params.store_name;

      this.status.year = false;
      this.reportYear = "";

      this.status.store = false;
      this.filternamestore = "";
      this.filterstore = "";
      
      this.$refs["base-table"].retrieveData();
    },

    handleCloseChips(mode) {
      switch (mode) {
        case "year":
          this.status.year = false;
          this.reportYear = "";
          this.resetFilter();
          this.$refs["base-table"].retrieveData();
          break;
        case "store":
          this.filterstore = "";
          this.filternamestore = "";
          this.status.store = false;
          this.$refs["base-table"].params.store_name &&
            delete this.$refs["base-table"].params.store_name;
          this.$refs["base-table"].retrieveData();
          break;
      }
    },

    responseDataStart() {
      this.loading = true;
      this.process.run = true;
      this.displayBaseTable = false;
      this.emptyData = false;
      /**
       * contains handler for filter params
       * date
       */

      //DATE - YEAR
      if (this.reportYear) {
        this.$refs["base-table"].params.year = this.reportYear;
        //show chips
        this.status.year = true;
      }

      // STORE
      if (this.filterstore != "") {
        this.$refs["base-table"].params.store_id = this.filterstore;
        // this.$refs["base-table"].params["filter[0][type]"] = "string";
        // this.$refs["base-table"].params["filter[0][field]"] = "store_name";
        // this.$refs["base-table"].params["filter[0][comparison]"] = "==";
        // this.$refs["base-table"].params["filter[0][value]"] = this.filterstore;
        this.outlet.forEach((item) => {
          this.status.store = true;
          if (item.store_id == this.filterstore) {
            this.filternamestore = item.store_name;
          }
        })
      }
    },

    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      this.fetchSummaryData()
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    async fetchSummaryData (store_id) {
      const response  = await get(`franchise/report/recap/transaction/tahunan/summary`, {
        params: {
          recap_job_id: this.$route.params.id,
          store_id: this.filterstore,
          year: this.reportYear
        }
      })

      let res = response.data

      if (res.status == 200) {
        this.total = res.results.data
      }
    },

    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;
      // //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },
    /**
     * END EVENTS
     */

    disabledAfterThisYear(date) {
      const today = new Date().getFullYear();

      return date.getFullYear() > today;
    },
    async Export() {
      this.process.formExport = true;
      this.process.formExport = false;
    },
    listTahun() {
      const currentYear = (new Date()).getFullYear();
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
      this.year = range(2020, currentYear, 1);
    },
    
    fetchOutlet() {
      get(`franchise/report/recap_job/transaction/store`, {
        params: {
          id: this.$route.params.id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.outlet = res.results.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
  },
};
</script>
<style scoped>
.background-bank-cols {
  background-color: #cfd8dc;
  border: 3px solid #cfd8dc;
  border-radius: 10px;
}
</style>
